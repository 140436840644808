import moment from 'moment'
import { t } from 'services/i18n'

import { COMPARISON_TYPES, DATE_RANGES } from 'constants/calendar'

export const createCalendarDateRanges = ({ minDate, maxDate }) => {
  const now = moment()
  const startDate = minDate
    ? moment(minDate)
    : moment('1994-01-01').startOf('day')
  const endDate = maxDate ? moment(maxDate) : now.clone().endOf('day')

  const ranges = [
    {
      rangeType: DATE_RANGES.allTime,
      label: t('common:CalendarRanges.allTime', 'All Time'),
      startDate: startDate.clone(),
      endDate: endDate.clone()
    },
    {
      rangeType: DATE_RANGES.today,
      label: t('common:CalendarRanges.today', 'Today'),
      startDate: now.clone(),
      endDate: now.clone()
    },
    {
      rangeType: DATE_RANGES.yesterday,
      label: t('common:CalendarSelect.datePicker.yesterday', 'Yesterday'),
      startDate: now.clone().subtract(1, 'day'),
      endDate: now.clone().subtract(1, 'day')
    },
    {
      rangeType: DATE_RANGES.thisWeek,
      label: t('common:CalendarSelect.datePicker.thisWeek', 'This Week'),
      startDate: now.clone().startOf('week'),
      endDate: now.clone()
    },
    {
      rangeType: DATE_RANGES.lastWeek,
      label: t('common:CalendarSelect.datePicker.lastWeek', 'Last Week'),
      startDate: now
        .clone()
        .subtract(1, 'week')
        .startOf('week'),
      endDate: now
        .clone()
        .subtract(1, 'week')
        .endOf('week')
    },
    {
      rangeType: DATE_RANGES.last7Days,
      label: t('common:CalendarSelect.datePicker.lastSevenDays', 'Last 7 Days'),
      startDate: endDate.clone().subtract(6, 'days'),
      endDate: endDate.clone()
    },
    {
      rangeType: DATE_RANGES.last14Days,
      label: t(
        'common:CalendarSelect.datePicker.lastFourteenDays',
        'Last 14 Days'
      ),
      startDate: endDate.clone().subtract(13, 'days'),
      endDate: endDate.clone()
    },
    {
      rangeType: DATE_RANGES.last30Days,
      label: t(
        'common:CalendarSelect.datePicker.lastThirtyDays',
        'Last 30 Days'
      ),
      startDate: endDate.clone().subtract(29, 'days'),
      endDate: endDate.clone()
    },
    {
      rangeType: DATE_RANGES.last90Days,
      label: t(
        'common:CalendarSelect.datePicker.lastNinetyDays',
        'Last 90 days'
      ),
      startDate: endDate.clone().subtract(89, 'days'),
      endDate: endDate.clone()
    },
    {
      rangeType: DATE_RANGES.thisMonth,
      label: t('common:CalendarSelect.datePicker.mtd', 'MTD'),
      startDate: now.clone().startOf('month'),
      endDate: now.clone()
    },
    {
      rangeType: DATE_RANGES.lastMonth,
      label: t('common:CalendarSelect.datePicker.lastMonth', 'Last Month'),
      startDate: now
        .clone()
        .subtract(1, 'month')
        .startOf('month'),
      endDate: now
        .clone()
        .subtract(1, 'month')
        .endOf('month')
    },
    {
      rangeType: DATE_RANGES.last12Months,
      label: t('common:CalendarRanges.lastMonths', 'Last {{count}} Months', {
        count: 12
      }),
      startDate: endDate.clone().subtract(12, 'months'),
      endDate: endDate.clone()
    },
    {
      rangeType: DATE_RANGES.thisYear,
      label: t('common:CalendarSelect.datePicker.ytd', 'YTD'),
      startDate: now.clone().startOf('year'),
      endDate: now.clone()
    },
    {
      rangeType: DATE_RANGES.lastYear,
      label: t('common:CalendarSelect.datePicker.lastYear', 'Last Year'),
      startDate: now
        .clone()
        .subtract(1, 'year')
        .startOf('year'),
      endDate: now
        .clone()
        .subtract(1, 'year')
        .endOf('year')
    },
    {
      rangeType: DATE_RANGES.thisQuarter,
      label: t('common:CalendarSelect.datePicker.qtd', 'QTD'),
      startDate: now.clone().startOf('quarter'),
      endDate: now.clone()
    },
    {
      rangeType: DATE_RANGES.lastQuarter,
      label: t('common:CalendarSelect.datePicker.lastQuarter', 'Last Quarter'),
      startDate: now
        .clone()
        .subtract(1, 'quarter')
        .startOf('quarter'),
      endDate: now
        .clone()
        .subtract(1, 'quarter')
        .endOf('quarter')
    }
  ]
  // Filter out ranges that are outside of the minDate and maxDate
  const allowedRanges = ranges.filter(
    range =>
      range.startDate.isSameOrAfter(startDate) &&
      range.endDate.isSameOrBefore(endDate)
  )

  // Transform Moment objects to Date objects
  const formattedRanges = allowedRanges.map(range => ({
    ...range,
    startDate: range.startDate.toDate(),
    endDate: range.endDate.toDate()
  }))

  return formattedRanges
}

/**
 * Returns the right dates, if range is not known (custom), it will return the custom dates
 * @param {Date|string} customStartDate
 * @param {Date|string} customEndDate
 * @param {Object} selectedRange
 * @returns {Object} { startDate: Date, endDate: Date }
 */
export const getSelectedDates = ({
  customStartDate,
  customEndDate,
  selectedRange
}) => {
  // Custom or unknown range
  if (!selectedRange) {
    return {
      startDate: moment(customStartDate).toDate(),
      endDate: moment(customEndDate).toDate()
    }
  }

  return {
    startDate: selectedRange?.startDate
      ? moment(selectedRange.startDate).toDate()
      : undefined,
    endDate: selectedRange?.endDate
      ? moment(selectedRange.endDate).toDate()
      : undefined
  }
}

export const getComparisonDates = ({
  startDate,
  endDate,
  rangeType,
  comparisonType,
  increment = 1 /* For usage of multiple previous periods */
}) => {
  if (!startDate || !endDate || !rangeType) {
    return {}
  }

  const start = moment(startDate)
  const end = moment(endDate)

  if (comparisonType === COMPARISON_TYPES.previousYear) {
    return {
      comparisonStartDate: start
        .clone()
        .subtract(1, 'year')
        .toDate(),
      comparisonEndDate: end
        .clone()
        .subtract(1, 'year')
        .toDate()
    }
  }

  const getSubstractedDates = (value, type) => ({
    comparisonStartDate: start
      .clone()
      .subtract(value, type)
      .startOf(type)
      .toDate(),
    comparisonEndDate: end
      .clone()
      .subtract(value, type)
      .endOf(type)
      .toDate()
  })

  const getDatesByType = type => {
    const diff = (end.diff(start, type) + 1) * increment
    return getSubstractedDates(diff, type)
  }

  switch (rangeType) {
    case DATE_RANGES.thisWeek || DATE_RANGES.lastWeek:
      return getSubstractedDates(1, 'week')
    case DATE_RANGES.thisMonth || DATE_RANGES.lastMonth:
      return getSubstractedDates(1, 'month')
    case DATE_RANGES.last12Months:
      return getSubstractedDates(12, 'months')
    case DATE_RANGES.thisQuarter || DATE_RANGES.lastQuarter:
      return getSubstractedDates(1, 'quarter')
    case DATE_RANGES.thisYear || DATE_RANGES.lastYear:
      return getSubstractedDates(1, 'year')
    default: {
      return getDatesByType('days')
    }
  }
}

/**
 * Creates a custom range when rangeType is 'custom'
 * @param {Date|string} customStartDate
 * @param {Date|string} customEndDate
 * @param {string} rangeType
 */
export const getCustomRange = ({
  customStartDate,
  customEndDate,
  rangeType
}) => {
  if (!customStartDate || !customEndDate || rangeType !== 'custom') {
    return undefined
  }

  return {
    rangeType: 'custom',
    label: t('common:CalendarRanges.customItem', 'Custom'),
    startDate: moment(customStartDate).toDate(),
    endDate: moment(customEndDate).toDate()
  }
}

/* Validate the selected range type, if not valid uses the default, if not valid uses the first one available */
export const getSelectedRange = ({ defaultRangeType, rangeType, ranges }) => {
  // Sometimes we receive values like "last_week" instead of "lastWeek". This aims to support both on most cases
  const cleanRangeType = rangeType?.toLowerCase()?.replace('_', '')
  const currentValue = cleanRangeType
    ? ranges.find(r => r.rangeType?.toLowerCase() === cleanRangeType)
    : undefined
  if (currentValue) {
    return currentValue
  }

  const defaultValue = defaultRangeType
    ? ranges.find(r => r.rangeType === defaultRangeType)
    : undefined

  if (defaultValue) {
    return defaultValue
  }

  return rangeType ? ranges[0] || {} : {}
}
