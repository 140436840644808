import {
  endOfDay,
  startOfDay,
  subDays,
  subWeeks,
  subMonths,
  subYears,
  differenceInDays
} from 'date-fns'
import { t } from 'services/i18n'

import { COMPARISON_TYPES } from 'constants/calendar'

export const DEFAULT_RANGE_TYPE = '1 Year'

// This can't go into the constant file because it needs to be created dynamically
// As a constant the today's date would always be the same when leaving the app open for several days

export const createDateRanges = (baseDate, dataIds) => [
  {
    name: t('common:CalendarSelect.datePicker.yesterday', 'Yesterday'),
    startDate: baseDate
      .clone()
      .subtract(1, 'day')
      .startOf('day'),
    endDate: baseDate
      .clone()
      .subtract(1, 'day')
      .endOf('day'),
    dataId: dataIds?.Yesterday,
    rangeType: 'Yesterday'
  },
  {
    name: t('common:CalendarSelect.datePicker.thisWeek', 'This Week'),
    startDate: baseDate.clone().startOf('week'),
    endDate: baseDate.clone(),
    dataId: dataIds?.ThisWeek,
    rangeType: 'This Week'
  },
  {
    name: t('common:CalendarSelect.datePicker.lastSevenDays', 'Last 7 Days'),
    startDate: baseDate
      .clone()
      .subtract(6, 'days')
      .startOf('day'),
    endDate: baseDate.clone(),
    dataId: dataIds?.LastSevenDays,
    rangeType: 'Last 7 Days'
  },
  {
    name: t('common:CalendarSelect.datePicker.lastWeek', 'Last Week'),
    startDate: baseDate
      .clone()
      .subtract(1, 'week')
      .startOf('week'),
    endDate: baseDate
      .clone()
      .subtract(1, 'week')
      .endOf('week'),
    dataId: dataIds?.LastWeek,
    rangeType: 'Last Week'
  },
  {
    name: t(
      'common:CalendarSelect.datePicker.lastFourteenDays',
      'Last 14 Days'
    ),
    startDate: baseDate
      .clone()
      .subtract(13, 'days')
      .startOf('day'),
    endDate: baseDate.clone(),
    dataId: dataIds?.LastFourteenDays,
    rangeType: 'Last 14 Days'
  },
  {
    name: t('common:CalendarSelect.datePicker.lastThirtyDays', 'Last 30 Days'),
    startDate: baseDate
      .clone()
      .subtract(29, 'days')
      .startOf('day'),
    endDate: baseDate.clone(),
    dataId: dataIds?.LastThirtyDays,
    rangeType: 'Last 30 Days'
  },
  {
    name: t('common:CalendarSelect.datePicker.lastMonth', 'Last Month'),
    startDate: baseDate
      .clone()
      .subtract(1, 'month')
      .startOf('month'),
    endDate: baseDate
      .clone()
      .subtract(1, 'month')
      .endOf('month'),
    dataId: dataIds?.LastMonth,
    rangeType: 'Last Month'
  },
  {
    name: t('common:CalendarSelect.datePicker.lastNinetyDays', 'Last 90 days'),
    startDate: baseDate
      .clone()
      .subtract(89, 'days')
      .startOf('day'),
    endDate: baseDate.clone(),
    dataId: dataIds?.LastNinetyDays,
    rangeType: 'Last 90 Days'
  },
  {
    name: t('common:CalendarSelect.datePicker.lastQuarter', 'Last Quarter'),
    startDate: baseDate
      .clone()
      .subtract(1, 'quarter')
      .startOf('quarter'),
    endDate: baseDate
      .clone()
      .subtract(1, 'quarter')
      .endOf('quarter'),
    dataId: dataIds?.LastQuarter,
    rangeType: 'Last Quarter'
  },
  {
    name: t('common:CalendarSelect.datePicker.1year', '1 Year'),
    startDate: baseDate.clone().subtract(365, 'days'),
    endDate: baseDate.clone(),
    dataId: dataIds?.OneYear,
    rangeType: '1 Year'
  },
  {
    name: t('common:CalendarSelect.datePicker.lastYear', 'Last Year'),
    startDate: baseDate
      .clone()
      .subtract(1, 'year')
      .startOf('year'),
    endDate: baseDate
      .clone()
      .subtract(1, 'year')
      .endOf('year'),
    dataId: dataIds?.LastYear,
    rangeType: 'Last Year'
  },
  {
    name: t('common:CalendarSelect.datePicker.mtd', 'MTD'),
    startDate: baseDate.clone().startOf('month'),
    endDate: baseDate.clone(),
    dataId: dataIds?.MTD,
    rangeType: 'MTD'
  },
  {
    name: t('common:CalendarSelect.datePicker.qtd', 'QTD'),
    startDate: baseDate.clone().startOf('quarter'),
    endDate: baseDate.clone(),
    dataId: dataIds?.QTD,
    rangeType: 'QTD'
  },
  {
    name: t('common:CalendarSelect.datePicker.ytd', 'YTD'),
    startDate: baseDate.clone().startOf('year'),
    endDate: baseDate.clone(),
    dataId: dataIds?.YearToDate,
    rangeType: 'YTD'
  },
  {
    name: t('common:CalendarSelect.datePicker.2years', '2 Years'),
    startDate: baseDate.clone().subtract(2, 'year'),
    endDate: baseDate.clone(),
    dataId: dataIds?.TwoYears,
    rangeType: '2 Years'
  }
]

export const computePreviousPeriodRange = (
  startDate,
  endDate,
  comparisonType,
  dateRangeType
) => {
  if (!(startDate && endDate)) {
    return {}
  }

  if (comparisonType && comparisonType === COMPARISON_TYPES.previousYear) {
    return {
      comparedStartDate: startOfDay(subYears(startDate, 1)),
      comparedEndDate: startOfDay(subYears(endDate, 1))
    }
  }

  if (!dateRangeType) {
    return {}
  }

  switch (dateRangeType) {
    case 'today' || 'yesterday':
      return {
        comparedStartDate: startOfDay(subDays(startDate, 1)),
        comparedEndDate: endOfDay(subDays(endDate, 1))
      }
    case 'weekToDate' || 'lastWeek':
      return {
        comparedStartDate: startOfDay(subWeeks(startDate, 1)),
        comparedEndDate: endOfDay(subWeeks(endDate, 1))
      }
    case 'monthToDate' || 'lastMonth':
      return {
        comparedStartDate: startOfDay(subMonths(startDate, 1)),
        comparedEndDate: endOfDay(subMonths(endDate, 1))
      }
    case 'last30Days':
      return {
        comparedStartDate: startOfDay(subDays(startDate, 29)),
        comparedEndDate: endOfDay(subDays(endDate, 30))
      }
    case 'yearToDate' || 'lastYear':
      return {
        comparedStartDate: startOfDay(subYears(startDate, 1)),
        comparedEndDate: endOfDay(subYears(endDate, 1))
      }
    case 'quarterToDate' || 'lastQuarter':
      return {
        comparedStartDate: startOfDay(subMonths(startDate, 3)),
        comparedEndDate: endOfDay(subMonths(endDate, 3))
      }
    case 'last12Months':
      return {
        comparedStartDate: startOfDay(subMonths(startDate, 12)),
        comparedEndDate: endOfDay(subMonths(endDate, 12))
      }
    default: {
      // the difference between days is smaller by one than the actual number of days
      const rangeInDays = differenceInDays(endDate, startDate) + 1
      return {
        comparedStartDate: startOfDay(subDays(startDate, rangeInDays)),
        comparedEndDate: endOfDay(subDays(endDate, rangeInDays))
      }
    }
  }
}
